import React, { useContext, useState } from 'react';
import { proxy, ref, subscribe } from 'valtio';

import {
  type DtoBlock,
  type DtoGame,
  type DtoGamePack,
  type DtoSendTrainingEditorMessageRequest,
  type DtoUpdateGamePackRequest,
  EnumsGamePackChangeLevel,
  type ModelsLogicSettings,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { apiService } from '../../../services/api-service';
import { fromDTOBlocks, fromDTOGame } from '../../../utils/api-dto';
import { sleep, uuidv4 } from '../../../utils/common';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../../utils/valtio';
import { GameEditorStore } from '../../Game/GameEditorStore';
import { BlockKnifeV2Utils } from '../../GameV2/Shared';
import { type TrainingEditorState } from './types';

type GameBlocksEntry = { gameId: string; blocks: Block[] };
type Callback = (entries: GameBlocksEntry[]) => void;

export class TrainingEditorControlAPI {
  private unsubscribes: (() => void)[];
  private callbacks: Set<Callback>;

  private _state: ValtioSnapshottable<TrainingEditorState>;

  constructor(
    pack: DtoGamePack,
    games: DtoGame[],
    blocks: DtoBlock[],
    isPreviewing = false
  ) {
    this.unsubscribes = [];
    this.callbacks = new Set();

    const stores = games.map((game) => {
      const store = new GameEditorStore();
      store.setEditingGame(fromDTOGame(game), {
        blocks: fromDTOBlocks(blocks.filter((b) => b.gameId === game.id)) || [],
      });
      store.setSelectedBlockId(null);
      return ref(store);
    });

    this._state = markSnapshottable(
      proxy<TrainingEditorState>({
        pack,
        stores,

        selectedGameId: null,
        selectedStore: null,

        aiChatStatus: 'closed',
        aiChatMessageSending: false,
        blockDirtyKey: null,
        previewBlock: null,
        requiresRender: false,
        isRendering: false,
        isPreviewing: false,
        isRebuildingStores: false,
      })
    );

    this._state.isPreviewing = isPreviewing;
    this.selectGame(games.at(0)?.id ?? null);
  }

  get state() {
    return this._state;
  }

  setIsPreviewing(isPreviewing: boolean) {
    this._state.isPreviewing = isPreviewing;
  }

  async openAIChat() {
    this.clearPreviewBlock();

    this.state.aiChatStatus = 'opening';
    await sleep(700);
    this.state.aiChatStatus = 'open';
  }

  async closeAIChat() {
    this.clearPreviewBlock();

    this.state.aiChatStatus = 'closing';
    await sleep(500);
    this.state.aiChatStatus = 'closed';
  }

  async sendAIChatMessage(
    blockId: string,
    dto: DtoSendTrainingEditorMessageRequest
  ) {
    this.clearPreviewBlock();

    try {
      this.state.aiChatMessageSending = true;
      const resp = await apiService.training.sendEditorChatMessage(
        blockId,
        dto
      );
      return resp.data;
    } finally {
      this.state.aiChatMessageSending = false;
    }
  }

  async clearAIChat(blockId: string) {
    await apiService.training.deleteEditorThread(blockId);
  }

  markCurrentBlockDirty() {
    this.state.blockDirtyKey = uuidv4();
  }

  previewBlock(block: Block) {
    this.state.previewBlock = block;
  }

  clearPreviewBlock() {
    this.state.previewBlock = null;
  }

  selectGame(gameId: string | null) {
    this.state.selectedGameId = gameId;
    const store = this.state.stores.find((s) => s.state.game?.id === gameId);
    this.state.selectedStore = store ? ref(store) : null;
  }

  selectBlock = (gameId: string, blockId: string | null) => {
    this.selectGame(gameId);
    const store = this.state.selectedStore;
    if (!store) return;
    store.setSelectedBlockId(blockId);
  };

  async updateGamePack(req: DtoUpdateGamePackRequest) {
    const resp = await apiService.gamePack.update(this.state.pack.id, req);
    ValtioUtils.update(this.state.pack, resp.data.gamePack);
  }

  async addNewSlideGroup() {
    const resp = await apiService.game.create({
      name: `Untitled Slide Group`,
    });
    if (!resp.data.game) return;
    const store = new GameEditorStore();
    store.setEditingGame(resp.data.game, {
      blocks: [],
    });
    store.setSelectedBlockId(null);

    const position = this.state.stores.findIndex(
      (s) => s.state.game?.id === this.state.selectedGameId
    );
    const newPosition =
      position === -1 ? this.state.stores.length : position + 1;

    this.state.stores.splice(newPosition, 0, ref(store));
    this.selectGame(resp.data.game.id);
    await this.updateGamePack({
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: this.state.stores.map((s) => s.state.game?.id || ''),
    });
  }

  async moveSlideGroup(from: number, to: number) {
    const store = this.state.stores[from];
    this.state.stores.splice(from, 1);
    this.state.stores.splice(to, 0, store);
    await this.updateGamePack({
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: this.state.stores.map((s) => s.state.game?.id || ''),
    });
  }

  async deleteSlideGroup(id: string, logicSettings?: ModelsLogicSettings) {
    const storeIndex = this.state.stores.findIndex(
      (s) => s.state.game?.id === id
    );
    if (storeIndex === -1) return;

    this.state.stores.splice(storeIndex, 1);
    if (this.state.selectedGameId === id) {
      this.selectGame(null);
    }
    await this.updateGamePack({
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: this.state.stores.map((s) => s.state.game?.id || ''),
      logicSettings,
    });
  }

  async addNewAssessment() {
    const resp = await apiService.gamePack.addAssessment(this.state.pack.id);
    if (!resp.data.gamePack) return;
    ValtioUtils.update(this.state.pack, resp.data.gamePack);

    const assessmentGameId = resp.data.gamePack.assessmentSettings?.gameId;
    if (!assessmentGameId) return;

    const gameResp = await apiService.game.getGameById(assessmentGameId);
    const game = gameResp.data.game;
    if (!game) return;

    const store = new GameEditorStore();
    store.setEditingGame(game, {
      blocks: [],
    });
    store.setSelectedBlockId(null);
    this.state.stores.push(ref(store));
    this.selectGame(game.id);
  }

  async deleteAssessment(id: string, logicSettings?: ModelsLogicSettings) {
    const storeIndex = this.state.stores.findIndex(
      (s) => s.state.game?.id === id
    );
    if (storeIndex === -1) return;

    this.state.stores.splice(storeIndex, 1);
    if (this.state.selectedGameId === id) {
      this.selectGame(null);
    }

    await apiService.gamePack.removeAssessment(this.state.pack.id);
    await this.updateGamePack({
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      logicSettings,
    });
  }

  on(callback: Callback) {
    this.callbacks.add(callback);
    this.watch();
    return () => this.off(callback);
  }

  off(callback: Callback) {
    this.callbacks.delete(callback);
    if (this.callbacks.size === 0) {
      this.unwatch();
    }
  }

  getCoursePersonalityIds() {
    const freq = new Map<string, number>();
    const blocks = this.state.stores.flatMap(
      (s) => s.blockEditorStore.state.blocks
    );

    for (const block of blocks) {
      const pids = BlockKnifeV2Utils.GetPersonalityIds(block);
      for (const pid of pids) {
        const count = freq.get(pid) ?? 0;
        freq.set(pid, count + 1);
      }
    }

    const entries = [...freq.entries()];
    entries.sort((a, b) => b[1] - a[1]);
    return entries.map((a) => a[0]);
  }

  async rebuildStores() {
    this.state.isRebuildingStores = true;
    try {
      const resp = await apiService.gamePack.getGamePackById(
        this.state.pack.id,
        {
          blocks: true,
          games: true,
        }
      );
      ValtioUtils.update(this.state.pack, resp.data.gamePack);
      const _games = resp.data.games ?? [];
      const _blocks = resp.data.blocks ?? [];
      const existingStores = [...this.state.stores];

      this.state.stores.length = 0;
      for (const game of _games) {
        const existingStore = existingStores.find(
          (s) => s.state.game?.id === game.id
        );
        const store = new GameEditorStore();
        store.setEditingGame(fromDTOGame(game), {
          blocks:
            fromDTOBlocks(_blocks.filter((b) => b.gameId === game.id)) || [],
        });
        store.setSelectedBlockId(existingStore?.state.selectedBlockId ?? null);
        this.state.stores.push(ref(store));
      }

      if (this.state.selectedGameId) {
        // reselect the game to induce a reactive change...it's a new store.
        this.selectGame(this.state.selectedGameId);
      } else if (_games.length > 0) {
        // select the first one
        this.selectGame(_games[0].id);
      }
    } finally {
      this.state.isRebuildingStores = false;
    }
  }

  private watch() {
    this.unwatch();
    // Initial sync
    const gameBlocks = this.buildGameBlocks();
    for (const callback of this.callbacks) {
      callback(gameBlocks);
    }

    const unsubscribeStores = subscribe(this.state.stores, () => {
      // sync if stores change...
      const gameBlocks = this.buildGameBlocks();
      for (const callback of this.callbacks) {
        callback(gameBlocks);
      }

      // sync when blocks change...
      for (const store of this.state.stores) {
        const unsubscribe = subscribe(store.blockEditorStore.state, () => {
          const gameBlocks = this.buildGameBlocks();
          for (const callback of this.callbacks) {
            callback(gameBlocks);
          }
        });
        this.unsubscribes.push(unsubscribe);
      }
    });
    this.unsubscribes.push(unsubscribeStores);
  }

  private buildGameBlocks() {
    const entries: GameBlocksEntry[] = [];

    for (const store of this.state.stores) {
      if (!store.state.game) continue;
      entries.push({
        gameId: store.state.game.id,
        blocks: ValtioUtils.detachCopy(
          store.blockEditorStore.state.blocks ?? []
        ),
      });
    }

    return entries;
  }

  private unwatch() {
    for (const unsubscribe of this.unsubscribes) {
      unsubscribe();
    }
    this.unsubscribes = [];
  }

  dispose() {
    this.unwatch();
    this.callbacks.clear();
  }
}

const context = React.createContext<TrainingEditorControlAPI | null>(null);
export const TrainingEditorControlAPIProvider = context.Provider;

export function useTrainingEditorControlAPI() {
  const ctrl = useContext(context);
  if (!ctrl) throw new Error('TrainingEditorControlAPIProvider not in tree!');
  return ctrl;
}

export function useTrainingEditorCoursePersonalityIds() {
  const ctrl = useContext(context);

  const [coursePersonalityIds] = useState<string[]>(
    () => ctrl?.getCoursePersonalityIds() ?? []
  );

  return coursePersonalityIds;
}
